import React, {useEffect} from 'react';
import './App.css';
import {Route, Routes} from "react-router-dom";
import MediateInfo from "./pages/mediateInfo";
import Chat from "./pages/chat";
import LawyerLetter from "./pages/lawyerLetter";
import Fayuan from "./pages/fayuan";

function App() {
    useEffect(() => {
        console.log('==========启动成功==========')
    }, [])
    return (
        <div className="App">
            <Routes>
                {/*<Route path={'/'} element={<MediateInfo/>}/>*/}
                {/*<Route path={'/chat'} element={<Chat/>}/>*/}
                <Route path={'/'} element={<LawyerLetter/>}/>
                <Route path={'/letter'} element={<LawyerLetter/>}/>
                <Route path={'/gov_fayuan'} element={<Fayuan/>}/>
            </Routes>
        </div>
    );
}

export default App;
