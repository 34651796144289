import React from "react";
import {Image} from "antd-mobile";

const Fayuan: React.FC = () => {
    return (
        <div>
            <Image style={{width: '100%'}} src={require("../assets/ic_fayuan.jpg")}/>
        </div>
    )
}
export default Fayuan;
