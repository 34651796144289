import {UAParser} from "ua-parser-js";

export function replaceTemplate(template: string, replacements: Record<string, string>): string {
    let message = template;
    for (const key in replacements) {
        if (message.includes('${' + key + '}')) {
            message = message.replace('${' + key + '}', replacements[key]);
        }
    }
    return message;
}


export function dateFormatter(nowDate: Date) : string {
    return nowDate.getFullYear() + '-' + (nowDate.getMonth() + 1)
        + '-' + nowDate.getDate() + ' ' + nowDate.getHours() + ':' +
        nowDate.getMinutes() + ':' + nowDate.getSeconds()
}

export function generateBuryingPointData(event: string, label: string, caseId: number) {
    const parser = new UAParser();
    return {
        event,
        label,
        caseId,
        platform: parser.getBrowser() ? parser.getBrowser().name : '',
        manufacturer: parser.getDevice() ? parser.getDevice().vendor : '',
        model: parser.getDevice() ? parser.getDevice().model : '',
        systemVersion: parser.getOS() ? parser.getOS().version : '',
        systemName: parser.getOS() ? parser.getOS().name : '',
    }
}
