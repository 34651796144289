import axios from "axios";
// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.REACT_APP_ENV === 'production' ? 'https://dbgw.cqxbst.cn' : 'http://10.1.100.230:12000',
  timeout: 100000 // 请求超时时间
});

export const GetBaseWsUrl = () => {
  const env = process.env.REACT_APP_ENV
  if (env === 'development') {
    return 'ws://10.0.110.150:13000';
  } else if (env === 'test') {
    return 'ws://10.0.110.150:13000';
  } else {
    return 'wss://bk.cqxbst.cn'
  }
}

// 异常拦截处理器
const errorHandler = (error) => {
  if (error.response) {
    if (error.response.status === 403) {
      console.log('==========403==========')
    }
    if (error.response.status === 401) {
      console.log('==========401==========')
    }
  }
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  // const token = storage.get(ACCESS_TOKEN)
  // // 如果 token 存在
  // // 让每个请求携带自定义 token 请根据实际情况自行修改
  // if (token) {
  //     // config.headers. = token
  //     config.headers['Authorization'] = 'Bearer ' + token
  //     config.headers[ENTERPRISE_ID] = getLocalEnterpriseId()
  // }
  return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, errorHandler)

export default request
