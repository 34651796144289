import {useEffect, useState} from "react";
import './css/lawyerLetter.css'
import {Image, Input, Toast} from "antd-mobile";
import {generateBuryingPointData} from "../utils/Tools";
import {buryingPoint, checkSign, querySign} from "../apis/httpInterface";
import { Base64 } from 'js-base64';

const lawyerLetter = () => {
    const [userName, setUserName] = useState<string>('--')
    const [cid, setCid] = useState<number | null>(null);
    const [value, setValue] = useState('');
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    useEffect(() => {
        const queryString = window.location.search.slice(1);
        const decodedCid = Base64.decode(queryString);
        const urlParams = new URLSearchParams(decodedCid);
        const cidParam = urlParams.get('cid');
        if (cidParam) {
            setCid(parseInt(cidParam));
        }
    }, [])
    useEffect(() => {
        if (cid) {
            sumbitBuryingPoint("lawyer_homePage", "")
            Toast.show({icon: 'loading', content: '加载中....', duration: 0})
            querySign(cid).then(r => {
                Toast.clear()
                if (r.data) {
                    setUserName(r.data.name)
                    setFileUrl(r.data.path)
                } else {
                    setFileUrl(null)
                    Toast.show({icon: "fail", content: '查询失败'})
                }
            }).catch(err => {
                setFileUrl(null)
                Toast.clear()
                Toast.show({icon: "fail", content: '查询失败'})
            })
        }
    }, [cid]);

    const sumbitBuryingPoint = (event: string, label: string) => {
        buryingPoint(generateBuryingPointData(event, label, cid ? cid : 0)).then(r => {}).catch(err => {console.error(err)})
    }
    const inputOnChange = (value: string) => {
        setValue(value)
    }
    const sumbitAction = () => {
        if (value.length <= 0) {
            Toast.show({icon: "fail", content: '请输入身份证后六位!'})
            return;
        }
        sumbitBuryingPoint("lawyer_sumbit", "")
        //上报数据
        const data = {
            caseId: cid,
            idCard: value
        }
        Toast.show({icon: 'loading', content: '加载中....', duration: 0})
        checkSign(data).then((r:any) => {
            Toast.clear()
            if (r.executed) {
                if (fileUrl) {
                    shareOrOpenPdf(fileUrl)
                } else {
                    Toast.show({icon: "fail", content: '律师函未生成'})
                }
            } else {
                Toast.show({icon: "fail", content: r.message})
            }
        }).catch(err => {
            Toast.clear()
            Toast.show({icon: "fail", content: '查询失败'})
        })
    }
    const shareOrOpenPdf = (url: string) => {
        const a = document.createElement('a');
        a.href = url;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <div className={'letterRoot'}>
            <Image style={{marginTop: 130}} fit={"contain"} width={140} height={40} lazy={true}
                   src={require('../assets/ic_letter_title.png')}/>
            <div className={'detail-box'}>
                <div className={'top-box'}>
                    <span style={{fontSize: 18, fontWeight: "bold", color: '#0A0A0A'}}>您好！</span>
                    <span style={{fontSize: 14, fontWeight: 400, color: '#666666', marginTop: 10}}>请输入<span
                        style={{color: '#A81213'}}>身份证后六位</span>查看您的电子律师函</span>
                </div>
                <div className={'name-box'}>
                    <span className={'common-title'}>姓名</span>
                    <span className={'common-title'}>{userName}</span>
                </div>
                <div className={'idcard-box'}>
                    <span className={'common-title'}>身份证后六位</span>
                    <Input onChange={inputOnChange} style={{width: '30%'}} placeholder={'请输入身份证后六位'}/>
                </div>
            </div>
            <div onClick={sumbitAction} className={'submit-box'}>查看</div>
            <div className={'bottom-box'}>
                如有疑问<br/>请致电重庆中启律师事务所：023-8925 3509
            </div>
        </div>
    )
}
export default lawyerLetter;
